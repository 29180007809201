body {
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  background-color: #EDECEC;
  // -webkit-text-size-adjust: 100%;
}

h3 {
  font-weight: 300;

  span.parent-hint {
    font-weight: 400;
    line-height: 15px;
  }
  // line-height:inherit;
}

.btn-accent {
  color: white;

  &:focus,
  &:hover {
    color: white;
  }
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 15px;
}

a {
  font-weight: 400;
}

*:focus {
  outline: none !important;
}

input[type=file] {
  color: transparent;
}

input[type=file]:after {
  content: ' ' attr(filename) ' ';
  font-family: FontAwesome;
  margin-left: 5px;
  color: $accentDim;
}

.back-arrow {
  color: rgba(255,255,255, 0.5);
  font-size: 26px;
  display: block;
  float: left;
  padding-right: 10px;
  padding-bottom: 20px;

  &:focus,
  &:hover {
    color: rgba(255,255,255, 0.8);
    text-decoration: none;
  }
}
//temp refactor this

.page {
  .float-button {
    position: absolute;
    top: 10px;
    right: 15px;
  }
}

.page .panel-heading {
  position: relative;
}

.page .panel-heading .float-button {
  position: absolute;
  top: 33px !important;
  right: 20px !important;
}

.modal-body {
  .tab-content {
    padding-top: 25px;
  }
}

.panel {
  .panel-heading {
    h3 {
      margin-bottom: 0 !important;
      font-size: 24px;
    }
  }

  h4.panel-title a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  h3.panel-title a {
    font-weight: 200;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.panel .panel-collapse {
  .panel-body {
    padding: 0;

    .content {
      padding: 15px;
    }
  }
}

.panel-material {
  border: none;
  box-shadow: none;
  margin-bottom: 0;

  .panel-heading {
    padding-left: 0;
    background: none;
    border-bottom: 1px solid $accentDim;

    .panel-title {}
  }

  .panel-body {
    padding: 15px 0;

    table {
      th {
        width: 15%;
      }

      td,
      th {
        border: none;
        padding: 15px;
      }
    }
  }

  .icon-link {
    color: $secondaryTextDark;
    padding-left: 15px;

    &:hover {
      color: $accentDim;
    }
  }
}

.form-error {
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="text"],
  textarea {
    border-bottom: 2px solid $errorColor !important;
  }
  // .select2-container--default,
  // .select2-selection--single {
  //     border-bottom: 1px solid $errorColor !important;
  //     border-radius: 0px!important;
  // }
}
// .select2-container--default,
// .select2-selection--single,
// .select2-selection--multiple {
//     height: 34px!important;
//     box-shadow: none !important;
//     border: none !important;
//     border-bottom: 1px solid $neutral300!important;
//     border-radius: 0px!important;
//     background: none !important;
//     padding: 0 !important;
//     transition: all 300ms;
//     &:focus {
//         border-bottom: 1px solid $primaryDim !important;
//         border-radius: 0px!important;
//     }
//     &:disabled {
//         border-bottom: none !important;
//         border-radius: 0px!important;
//     }
// }

.border-top {
  border-top: 1px #CCCCCC solid;
  margin-left: -20px;
  margin-right: -20px;
}

.border-bot {
  border-bottom: 1px #CCCCCC solid;
  margin-left: -20px;
  margin-right: -20px;
}

.border-top-inside {
  border-top: 1px #CCCCCC solid;
  margin-left: 0;
  margin-right: 0;
}

.border-bot-inside {
  border-bottom: 1px #CCCCCC solid;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
}

.inside {
  margin-left: 0;
  margin-right: 0;
}

.inside {
  margin-left: 0;
  margin-right: 0;
}

.info-message {
  color: $hintsTextDark;
}

.no-margin-bot {
  margin-bottom: 0 !important;
}

.panel-card {
  label {
    margin-top: 9px;
  }

  .expansion-panel {
    .expansion-panel-head {
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      background-color: white;

      label {
        margin-top: 5px;
      }
    }

    .expansion-panel-body {
      display: none;

      .panel-table {
        td {
          border-top: none;
        }

        th {
          padding-top: 15px;
          padding-bottom: 15px;
        }

        .select2-container {
          margin-left: -8px;
        }
      }

      .expansion-panel-footer {
        padding-top: 0;
        padding-bottom: 0;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;

        .btn {
          padding-right: 9px !important;
        }
      }
    }

    .expansion-panel-body-open {
      .panel-table {
        td {
          border-top: none;
        }

        th {
          padding-top: 15px;
          padding-bottom: 15px;
        }

        .select2-container {
          margin-left: -8px;
        }
      }

      .expansion-panel-footer {
        padding-top: 0;
        padding-bottom: 0;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;

        .btn {
          padding-right: 9px !important;
        }
      }
    }

    .panel-toggle {
      padding-right: 9px;
    }

    .form-group {
      margin: 0 !important;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

.form-padding {
  margin: 0 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.error {
  color: $errorColor;
  font-weight: bold;
}

.row-fluid {
  width: 100%;
  overflow: hidden;
}

.label-center {
  padding: 6px 12px;
  margin-bottom: 0;
  color: $hintsTextDark;
}

.no-margin-header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: black;
}

.float-button-panel {
  margin-top: 26px;
}

.row-hover:hover {
  background-color: $neutral100;
}

.nav-bar-absolute {
  position: absolute;
  z-index: 100;
  top: 170px;
  padding-left: 37px;
  padding-right: 37px;
}

.list-form {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.btn-link-small {
  font-size: 14px!important;
  text-transform: none!important;
}

.btn-pink {
  // @extend .z-depth-1;
  background: $accentDim;
  color: white;
  // border-radius: 200px;
  transition: all 0.3s ease-out 0s;

  &:hover {
    color: white;
    // @extend .z-depth-2;
  }

  &:active {
    color: white;
  }

  &:focus {
    color: white;
  }
  // padding-left: 12px!important;
  // padding-right: 12px!important;
  i {
    margin: 0 !important;
  }
}

.no-margin {
  margin: 0;
}

.blue-divider {
  border-color: $primaryDark;
  margin: 1.5em -20px;
}

.show-more-onclick li:first-child,
.show-more-onclick tr:first-child {
  display: block !important;
}

.first-li,
.first-tr {
  cursor: pointer;
}


.show-more-onclick {
  li:first-child:not(:last-child) {
    cursor: pointer;
    transition: all ease .3s;
  }
  li:first-child:not(:last-child):after,
  tr:first-child:not(:last-child) td:after {
    transition: all ease .3s;
    content: '\f107';
    font-family: FontAwesome;
    margin-left: 5px;
    color: $accentDim;
  }

  li:first-child:not(:last-child).clicked:after,
  li:first-child:not(:last-child).clicked td:after {
    content: '\f106';
    font-family: FontAwesome;
    margin-left: 5px;
    color: $accentDim;
  }
}

.show-more-onclick {
  padding-left: 0 !important;
}

.show-more-onclick li,
.show-more-onclick tr {
  display: none;
  list-style-type: none;
}
@keyframes fadein {
  from {
    display: none!important;
    height: 1%;
    opacity: 0;
  }

  to {
    display: block!important;
    height: 100%;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    display: block!important;
    height: 100%;
    opacity: 1;
  }

  to {
    display: none!important;
    height: 1%;
    opacity: 0;
  }
}

.fadein-tr {
  display: block!important;
  animation-duration: 1s;
  animation-name: fadein;
}

.fadeout-tr {
  animation-duration: 1s;
  animation-name: fadeout;
}

.show-block {
  display: block!important;
}

.no-dot-list {
  padding: 0 !important;

  li {
    list-style-type: none;
  }
}

.clickable {
  cursor: pointer;

  li {
    cursor: pointer;
  }
}

.page-toolbar-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 0 76px !important;
}

.fixed-float-button {
  top: 20px !important;
  position: fixed !important;
  right: 55px !important;
}

.fixed-one {
  top: 20px!important;
  right: 0px!important;
  background-color: #d32f2f!important;
}

.fixed-two {
  top: 20px!important;
  right: 80px!important;
  background-color: #388e3c!important;
}

.fixed-one:disabled,
.fixed-two:disabled {
  opacity: 0;
  cursor: not-allowed;

  &:hover {
    @extend .z-depth-1;
  }
}

.fixed-one.fixed-float-button {
  right: 60px!important;
}

.fixed-two.fixed-float-button {
  right: 140px!important;
}

.slim-form-groups {
  margin-bottom: 10px !important;
}

.travel-agency-client {
  color: #6C9FD8;
}

.email-link {
  color: blue !important;
}
// .btn-pink{
//     color: #fff;
//     background-color: $accentDim;
//     border-color: transparent;
// }
