.page {
  padding: 40px 37px;
}
//------------------------------------------
// Login page
//------------------------------------------

.text-primary {
  color: $primaryDark;
}

.text-accent {
  color: $accentDim;
}

.page__login {
  background: #fff;
  padding: 0 !important;

  .status-bar {
    margin-bottom: 3%;
  }

  .logo {
    display: block;
    margin: 0 auto 35px;
    width: 35%;
  }

  h5 {
    text-align: center;
    color: $neutral700;
    margin-bottom: 25px;
  }

  .login-panel {
    padding: 25px 40px;
    background: $neutral50;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
  }

  .btn-login {
    width: 100%;
    border-radius: 2px;
    color: $neutral50;
    font-weight: bold;
  }

  .btn-forgot-password {
    display: block !important;
    text-align: center;
    color: $secondaryTextDark;
    text-transform: none;

    &:hover {
      color: $primaryTextDark;
    }
  }
}
//------------------------------------------
// Invoice create
// finance/invoice/create/partner
//------------------------------------------

.finance__invoice-partner {
  //@TODO: refactor hack
  h6 {
    background: #f0f0f0;
    padding: 5px 10px;
  }

  table {
    th {
      width: 350px;
    }
  }
}

.invoice-preview .card-head {
  h3 {
    display: block !important;
    text-align: center !important;
    float: none !important;
  }
}

.invoice-table {
  tbody {
    tr {
      td {
        border-top: none;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }
  }
}

.page.finance__invoice-create-confirm {
  table {
    td,
    th {
      border: none;
    }
  }
}
//------------------------------------------
// Ship index
//------------------------------------------

.ship__index {
  .ship-card {
    background: #fff;
    height: 200px;
    margin-bottom: 30px;
    @extend .z-depth-1;

    &__content {
      height: 170px;
      padding: 15px 20px;

      h4 {
        margin-top: 0;
      }

      p {
        overflow: hidden;
      }
    }

    &__meta {
      height: 170px;

      ul {
        @extend .clear-list;

        li {
          margin-bottom: 20px;
          color: $secondaryTextDark;
          text-align: left;
          float: left;
          margin-right: 15px;

          span {
            display: inline-block;
            min-width: 32px;
          }

          i {
            min-width: 15px;
            margin-right: 5px;
          }
        }
      }
    }

    &__image {
      width: 110px;
      height: 110px;
      overflow: hidden;
      float: left;
      border-radius: 50%;
      margin-right: 20px;
      background: $neutral100;

      img {
        height: inherit;
      }
    }

    &__action {
      text-align: right;
      padding: 0 20px;

      a {
        display: inline-block;
        padding-left: 20px;
        text-transform: uppercase;
        font-weight: 500;

        &:hover {
          text-decoration: none;
          color: $accentDim;
        }
      }
    }
  }
}
//------------------------------------------
// Ship show
//------------------------------------------

.ship__show {
  #cabin-types {
    table {
      td.img {
        width: 100px;

        .round {
          width: 75px;
          height: 75px;

          img {
            height: inherit;
            display: block;
          }
        }
      }
    }
  }
}
//------------------------------------------
// Contract create
//------------------------------------------

.contract__create {
  h6 {
    background: #f0f0f0;
    padding: 5px 10px;
  }
}
//------------------------------------------
// Contract show
//------------------------------------------

.contract__edit,
.contract__view {
  .table-no-border {
    td,
    th {
      border: none;
    }

    th {
      padding: 15px;
    }
  }

  h6 {
    background: #f0f0f0;
    padding: 5px 10px;
  }

  .span-label {
    display: inline-block;
    width: 40px;
  }
}
//------------------------------------------
// Status-bar
//------------------------------------------

.status-bar {
  background: $primaryDark;
  min-height: 25px;
}
//------------------------------------------
// Sidebar
//------------------------------------------
/* Hide for mobile, show later */

.sidebar {
  background: $neutral100;
  display: none;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
    -ms-overflow-style: none;
    /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;

    &:hover {
      overflow-y: auto;
    }
  }
}
//------------------------------------------
// Sidebar Profile
//------------------------------------------

.sidebar-profile {
  margin-right: -20px;
  margin-left: -20px;
  margin-top: -20px;
  height: 150px;
  background: $neutral200;
  padding: 15px 20px 0;
  position: relative;

  .profile-dropdown {
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    color: $neutral600;

    ul {
      top: 0;
      left: -140px;
      border-radius: 4px;

      li {
        padding-top: 5px;
        padding-bottom: 5px;

        i {
          min-width: 20px;
          width: 20px;
        }
      }
    }

    button {
      background: none;
      border: none;
    }
  }

  .heart {
    background-color: red;
    display: inline-block;
    height: 30px;
    margin: 0 10px;
    position: absolute;
    top: 20px;
    right: 100px;
    transform: rotate(-45deg);
    width: 30px;
  }

  .heart:before,
  .heart:after {
    content: "";
    background-color: red;
    border-radius: 50%;
    height: 30px;
    position: absolute;
    width: 30px;
  }

  .heart:before {
    top: -15px;
    left: 0;
  }

  .heart:after {
    left: 15px;
    top: 0;
  }

  .event-info {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    margin-left: -20px;
    color: red;
  }

  .profile-photo {
      width: 70px;
      text-align: center;
      background: #fff;
      height: 70px;
      overflow: hidden;
      display: block;
      border-radius: 50%;
      border: 2px solid #fff;
      margin: 0 auto;
      box-shadow: 0 1px 1px rgba(0,0,0,0.3);
    img {
      height: inherit;
    }
  }

  .profile-info {
    display: block;
    list-style: none;
    margin: 10px 0 0 10px;
    padding: 0;
    text-align: center;

    li {
      &.name {}

      &.usertype {
        font-size: 0.9em;
        color: $secondaryTextDark;
      }
    }
  }

  .profile-settings {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 35px;
    // background:rgba(0,0,0,0.5);
    line-height: 35px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
//------------------------------------------
// Sidebar Navigation
//------------------------------------------

.nav-sidebar {
  margin-right: -21px;
  /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}

.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:focus,
.nav-sidebar > .active > a:hover {
  color: #fff;
  background-color: #428bca;
}

.nav-sidebar-main {
  li {
    a {
      color: $primaryTextDark;
      font-size: 16px;
      padding: 20px;
      transition: color 0.3s ease-out;

      &:hover {
        color: $primaryDark;
      }

      i {
        min-width: 35px;
        margin-right: 10px;
      }
    }
  }
}
//------------------------------------------
// Main
//------------------------------------------

.main .page-header {
  margin-top: 0;
}

.content {
  padding: 0;
}
//------------------------------------------
// Page header
//------------------------------------------

.page-header {
  width: 100%;
  height: 100px;
  line-height: 110px;
  background: $primaryDark;
  color: #fff;
  transition: height 100ms;
  margin: 0 !important;
  border-bottom: 0;
  padding: 0 40px;

  .prev-page {
    transition: all 0.3s ease-out 0s;

    i {
      padding-bottom: 8px;
      padding-right: 20px;
    }

    &:hover {
      color: $primaryTextLight;
      text-decoration: none;
      cursor: pointer;
    }
  }

  span.section-title {
    font-size: 3.2rem;
    font-weight: 300;
    padding-left: 0;

    i {
      padding-bottom: 5px;
    }
  }
}
//------------------------------------------
// > Page toolbar
//------------------------------------------

.page-toolbar {
  min-height: 50px;
  box-shadow: 0 4px 5px rgba(0,0,0, 0.2);
  padding: 0 40px;
  background: $primaryDark;

  .navbar.navbar-default {
    border: none;
    margin-bottom: 0;

    li {
      a {
        text-transform: uppercase;
        font-weight: 500;
        border-bottom: 3px solid transparent;
        padding-left: 20px;
        padding-right: 20px;
      }

      ul.dropdown-menu {
        li {
          a {
            text-transform: none;
            font-weight: 400;
            margin-left: 0;
          }
        }
      }

      &.active {
        a {
          border-bottom: 3px solid #fff;
        }
      }

      &:first-child {
        a {
          margin-left: -20px;
        }
      }
    }
  }

  .navbar .container-fluid,
  .navbar-collapse {
    padding-left: 0;
  }

  .navbar-collapse.in {
    padding-left: 30px;
  }

  .navbar-toolbar {
    position: relative;

    a.float-button {
      @extend .z-depth-1;
      box-sizing: border-box;
      color: rgb(255, 255, 255);
      cursor: pointer;
      display: inline-block;
      height: 55.5px;
      letter-spacing: 0.5px;
      position: absolute;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
      width: 55.5px;
      will-change: opacity, transform;
      z-index: 1;
      perspective-origin: 27.75px 27.75px;
      transform: matrix(1, 0, 0, 1, 0, 0);
      transform-origin: 27.75px 27.75px;
      background: $accentDim none repeat scroll 0 0 / auto padding-box border-box;
      border: 0 none rgb(255, 255, 255);
      border-radius: 50% 50% 50% 50%;
      font: normal normal normal normal 15px/56px Roboto, sans-serif;
      outline: rgb(255, 255, 255) none 0;
      overflow: hidden;
      transition: box-shadow 0.3s ease-out 0s;
      right: 5px;
      top: 20px;

      &:hover {
        @extend .z-depth-2;
      }
    }
  }

  .nav.navbar-nav {
    .dropdown {
      .dropdown-menu {
        //top: 0;
      }
    }
  }
}

.no-margin-form {
  margin: 0 !important;
}

.padding-form {
  padding: 5px;
}

.panel-card {
  padding-bottom: 0 !important;
}

.small-table {
  font-size: 14px;
}

.bold {
  font-weight: bold;
  color: $primaryTextDark;
}

.exp-panel-card {
  padding: 0 !important;
}

.full-width-hr {
  margin: 0 -20px;
  padding: 10px 0;
}

.invisible {
  opacity: 0!important;
}

.subheader:before {
  content: ' ';
  display: block;
}

.input-info {
  pointer-events: none;
  transition: all 300ms!important;
  opacity: 0;
}
// .card-head{
//     .float-button{
//         position: absolute!important;
//         top: -25px!important;
//     }
// }

.empty-label {
  width: 100%;
  overflow: visible;
}

table {
  .bold-bottom {
    border-bottom: 2px solid #ddd;
  }
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.align-center {
  text-align: center;
}

.group-btn-md {
  border: 0 !important;
  background-color: transparent;
  border-bottom: 2px solid $neutral300 !important;
  transition: all 300ms!important;
  padding: 0 !important;
  &:focus {
    border-bottom: 2px solid $primaryDim !important;
  }
  &:hover {
    color: $primaryDim;
  }
}

.dataTables_filter,
.dataTables_info {
  display: none;
}

.guest-network-table,
.private-network-table,
.total_device_usage {
  overflow: auto;
}

table.fixedHeader-floating {
  position: fixed !important;
  top: 50px!important;
  background-color: white;
  margin-top: 0 !important;
}

table.fixedHeader-floating.no-footer {
  border-bottom-width: 0;
}

table.fixedHeader-locked {
  position: absolute !important;
  background-color: white;
}
@media print {
  table.fixedHeader-floating {
    display: none;
  }
}

.card-part-paddingless {
  margin-left: -20px !important;
  margin-right: -20px;
  margin-bottom: -20px !important;
}

.empty-label {
  width: 100%;
  overflow: visible;
}

.dropdown {
  position: relative!important;

  .dropdown-menu {
    left: inherit;
    padding: 0;
    margin: 0;

    a,
    button {
      box-shadow: none;
      width: 100%;
      text-align: center;
      border: none;
      color: black;
      background-color: white;
    }
    button:hover {
      background-color: #f5f5f5;
    }
  }
}

.badge-accent{
  background-color: $accentDim;
  a{
    color: white;
    &:hover, &:active, &:visited{
      text-decoration: none;
    }
  }
  &:hover{
    background-color: $primaryDim;
  }
}

.badge-hover-accent{
  &:hover{
    background-color: $primaryDim;
    cursor: pointer;
  }
}

.sub-toolbar {
  background-color: white;
  border: none!important;

  .active {
    border-bottom: 3px $primaryMedium solid;
  }

  li a {
    text-transform: uppercase!important;
    color: $primaryMedium!important;
  }
  @extend .z-depth-1;
}

.dropdown {
  position: inherit!important;
  .dropdown-menu {
    left: inherit;
  }
}

.commission-rate-table {
  tbody {
    p {
      margin-bottom: 1px !important;
    }

    ul {
      margin-bottom: 1px !important;
    }
  }
}

.popover {
  max-width: 200px;
  height: 250px;
}

.loader,
.loader:after,
.loader:before {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: $primaryDark;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:after,
.loader:before {
  content: '';
  position: absolute;
  top: 0;
}

.scrollable-table {
  overflow-x: auto;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  100%,
  80% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  100%,
  80% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.btn-flat {
  background-color: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
  text-transform: uppercase;

  &:active {
    background-color: $neutral400;
    box-shadow: none;
  }

  &:hover {
    background-color: $neutral300;
  }
}

.btn-accent-flat {
  @extend .btn-flat;
  color: $accentDim;

  &:hover {
    color: $accentDark;
  }

  &:focus {
    color: $accentDim;
  }
}

.modal-xlg{
  width:1300px;
}

.badge-primary{
  background-color: $primaryDim;
}

h3 p{
  color: black;
}

.table-list-ul {
  list-style: none;
  padding-left: 0;
  white-space: nowrap;
}

.table-condensed{
  tbody{
    td{
      padding-top: 3px!important;
      padding-bottom: 3px!important;
    }
  }
}

.pill-container{
  display: block;
  margin-bottom: 10px;
  overflow: hidden;
}

.drag-zone{
  transition: all linear .1s;
  .draggable{
    .dropdown-btn {
      color: white;
      &:hover {
        text-decoration: none;
      }
    }
    @extend .z-depth-1;
    cursor: pointer;
    background-color: $accentDim;
    color: white;
    padding: 0 5px;
    display: inline-block;
    margin: 4px;
    border-radius: 3px;
    &::after {
      content: ' ';
    }
    &::before {
      content: ' ';
    }
    &:hover{
      background-color: $primaryDim;
    }
  }
  .drag-zone-input {
    display: none;
  }
  border-bottom:2px solid $neutral300 !important;
  min-height: 58px;
}

.drag-zone-hover{
  transition: all linear .1s;
  @extend .z-depth-1;
}

.txt-btn{
  border: none;
  padding: 0;
  margin: 0;
  background-color: white;
  color: $accentDim;
  &:hover{
    color: $primaryDim;
  }
}

.queue-element {
  display: none;
  width: 0!important;
  height: 0!important;
}

.remove-card {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 100;
}

.with-summary-row {
  tr:nth-last-child(2) {
    border-top: solid black 2px;
  }
}

.text-right {
  text-align: right;
}

.form-group {
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
  }
}

ul.plain {
  margin: 0;
  list-style: none;
  padding: 0;
}
