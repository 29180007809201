//removing gutter (padding) from bootstrap column
.no-gutter{
    padding:0px;
}

.cpl{
  padding-left:0px;
}

.cpr{
  padding-right:0px;
}

.cml{
  margin-left: 0 !important;
}

.cmr{
  margin-right: 0 !important;
}

.clear-list{
  list-style:none;
  padding:0px;
  margin:0px;
}

.clear-list-spacing{
  padding: 0;
  margin: 0;
}

ul.horizontal{
    display: inline;
    list-style: none;
    margin:0px;
    padding:0px;

    li {
        display: inline;
    }

    li:after {
        content: ", ";
    }

    li:last-child:after {
        content: "";
    }
}

.inithide{
  display:none;
}

.inline-allocation-wrapper{
  display:inline-block;
  width:75px;
}

.label-required:after{
    content:'*';
    color:red;
    padding-left:5px;
}

//google material shadows from materialcss framework
.z-depth-0 {
  box-shadow: none !important;
}
.z-depth-1{
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow:0px 1px 3px rgba(0,0,0, 0.3);
}
.z-depth-1-half{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.z-depth-2{
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-3{
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-4{
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.z-depth-5{
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.col-odd-centered{
  float: none;
  margin: 0 auto;
}
