/* Material Design Iconic Font uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
/* If you do not want use all icons you can disable icon set here */

@import "icons/_actions";
@import "icons/_alert";
@import "icons/_audio-video";
@import "icons/_communication";
@import "icons/_content";
@import "icons/_device";
@import "icons/_editor";
@import "icons/_file";
@import "icons/_hardware";
@import "icons/_image";
@import "icons/_maps";
@import "icons/_navigation";
@import "icons/_notification";
@import "icons/_social";
@import "icons/_toggle";