.#{$md-css-prefix}-attachment:before {
  content: "\f19c";
}
.#{$md-css-prefix}-cloud:before {
  content: "\f19d";
}
.#{$md-css-prefix}-cloud-circle:before {
  content: "\f19e";
}
.#{$md-css-prefix}-cloud-done:before {
  content: "\f19f";
}
.#{$md-css-prefix}-cloud-download:before {
  content: "\f1a0";
}
.#{$md-css-prefix}-cloud-off:before {
  content: "\f1a1";
}
.#{$md-css-prefix}-cloud-queue:before {
  content: "\f1a2";
}
.#{$md-css-prefix}-cloud-upload:before {
  content: "\f1a3";
}
.#{$md-css-prefix}-file-download:before {
  content: "\f1a4";
}
.#{$md-css-prefix}-file-upload:before {
  content: "\f1a5";
}
.#{$md-css-prefix}-folder:before {
  content: "\f1a6";
}
.#{$md-css-prefix}-folder-open:before {
  content: "\f1a7";
}
.#{$md-css-prefix}-folder-shared:before {
  content: "\f1a8";
}