.#{$md-css-prefix}-cast:before {
  content: "\f1a9";
}
.#{$md-css-prefix}-cast-connected:before {
  content: "\f1aa";
}
.#{$md-css-prefix}-computer:before {
  content: "\f1ab";
}
.#{$md-css-prefix}-desktop-mac:before {
  content: "\f1ac";
}
.#{$md-css-prefix}-desktop-windows:before {
  content: "\f1ad";
}
.#{$md-css-prefix}-dock:before {
  content: "\f1ae";
}
.#{$md-css-prefix}-gamepad:before {
  content: "\f1af";
}
.#{$md-css-prefix}-headset:before {
  content: "\f1b0";
}
.#{$md-css-prefix}-headset-mic:before {
  content: "\f1b1";
}
.#{$md-css-prefix}-keyboard:before {
  content: "\f1b2";
}
.#{$md-css-prefix}-keyboard-alt:before {
  content: "\f1b3";
}
.#{$md-css-prefix}-keyboard-arrow-down:before {
  content: "\f1b4";
}
.#{$md-css-prefix}-keyboard-arrow-left:before {
  content: "\f1b5";
}
.#{$md-css-prefix}-keyboard-arrow-right:before {
  content: "\f1b6";
}
.#{$md-css-prefix}-keyboard-arrow-up:before {
  content: "\f1b7";
}
.#{$md-css-prefix}-keyboard-backspace:before {
  content: "\f1b8";
}
.#{$md-css-prefix}-keyboard-capslock:before {
  content: "\f1b9";
}
.#{$md-css-prefix}-keyboard-control:before {
  content: "\f1ba";
}
.#{$md-css-prefix}-keyboard-hide:before {
  content: "\f1bb";
}
.#{$md-css-prefix}-keyboard-return:before {
  content: "\f1bc";
}
.#{$md-css-prefix}-keyboard-tab:before {
  content: "\f1bd";
}
.#{$md-css-prefix}-keyboard-voice:before {
  content: "\f1be";
}
.#{$md-css-prefix}-laptop:before {
  content: "\f1bf";
}
.#{$md-css-prefix}-laptop-chromebook:before {
  content: "\f1c0";
}
.#{$md-css-prefix}-laptop-mac:before {
  content: "\f1c1";
}
.#{$md-css-prefix}-laptop-windows:before {
  content: "\f1c2";
}
.#{$md-css-prefix}-memory:before {
  content: "\f1c3";
}
.#{$md-css-prefix}-mouse:before {
  content: "\f1c4";
}
.#{$md-css-prefix}-phone-android:before {
  content: "\f1c5";
}
.#{$md-css-prefix}-phone-iphone:before {
  content: "\f1c6";
}
.#{$md-css-prefix}-phonelink:before {
  content: "\f1c7";
}
.#{$md-css-prefix}-phonelink-off:before {
  content: "\f1c8";
}
.#{$md-css-prefix}-security:before {
  content: "\f1c9";
}
.#{$md-css-prefix}-sim-card:before {
  content: "\f1ca";
}
.#{$md-css-prefix}-smartphone:before {
  content: "\f1cb";
}
.#{$md-css-prefix}-speaker:before {
  content: "\f1cc";
}
.#{$md-css-prefix}-tablet:before {
  content: "\f1cd";
}
.#{$md-css-prefix}-tablet-android:before {
  content: "\f1ce";
}
.#{$md-css-prefix}-tablet-mac:before {
  content: "\f1cf";
}
.#{$md-css-prefix}-tv:before {
  content: "\f1d0";
}
.#{$md-css-prefix}-watch:before {
  content: "\f1d1";
}