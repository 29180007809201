.#{$md-css-prefix}-attach-file:before {
  content: "\f168";
}
.#{$md-css-prefix}-attach-money:before {
  content: "\f169";
}
.#{$md-css-prefix}-border-all:before {
  content: "\f16a";
}
.#{$md-css-prefix}-border-bottom:before {
  content: "\f16b";
}
.#{$md-css-prefix}-border-clear:before {
  content: "\f16c";
}
.#{$md-css-prefix}-border-color:before {
  content: "\f16d";
}
.#{$md-css-prefix}-border-horizontal:before {
  content: "\f16e";
}
.#{$md-css-prefix}-border-inner:before {
  content: "\f16f";
}
.#{$md-css-prefix}-border-left:before {
  content: "\f170";
}
.#{$md-css-prefix}-border-outer:before {
  content: "\f171";
}
.#{$md-css-prefix}-border-right:before {
  content: "\f172";
}
.#{$md-css-prefix}-border-style:before {
  content: "\f173";
}
.#{$md-css-prefix}-border-top:before {
  content: "\f174";
}
.#{$md-css-prefix}-border-vertical:before {
  content: "\f175";
}
.#{$md-css-prefix}-format-align-center:before {
  content: "\f176";
}
.#{$md-css-prefix}-format-align-justify:before {
  content: "\f177";
}
.#{$md-css-prefix}-format-align-left:before {
  content: "\f178";
}
.#{$md-css-prefix}-format-align-right:before {
  content: "\f179";
}
.#{$md-css-prefix}-format-bold:before {
  content: "\f17a";
}
.#{$md-css-prefix}-format-clear:before {
  content: "\f17b";
}
.#{$md-css-prefix}-format-color-fill:before {
  content: "\f17c";
}
.#{$md-css-prefix}-format-color-reset:before {
  content: "\f17d";
}
.#{$md-css-prefix}-format-color-text:before {
  content: "\f17e";
}
.#{$md-css-prefix}-format-indent-decrease:before {
  content: "\f17f";
}
.#{$md-css-prefix}-format-indent-increase:before {
  content: "\f180";
}
.#{$md-css-prefix}-format-italic:before {
  content: "\f181";
}
.#{$md-css-prefix}-format-line-spacing:before {
  content: "\f182";
}
.#{$md-css-prefix}-format-list-bulleted:before {
  content: "\f183";
}
.#{$md-css-prefix}-format-list-numbered:before {
  content: "\f184";
}
.#{$md-css-prefix}-format-paint:before {
  content: "\f185";
}
.#{$md-css-prefix}-format-quote:before {
  content: "\f186";
}
.#{$md-css-prefix}-format-size:before {
  content: "\f187";
}
.#{$md-css-prefix}-format-strikethrough:before {
  content: "\f188";
}
.#{$md-css-prefix}-format-textdirection-l-to-r:before {
  content: "\f189";
}
.#{$md-css-prefix}-format-textdirection-r-to-l:before {
  content: "\f18a";
}
.#{$md-css-prefix}-format-underline:before {
  content: "\f18b";
}
.#{$md-css-prefix}-functions:before {
  content: "\f18c";
}
.#{$md-css-prefix}-insert-chart:before {
  content: "\f18d";
}
.#{$md-css-prefix}-insert-comment:before {
  content: "\f18e";
}
.#{$md-css-prefix}-insert-drive-file:before {
  content: "\f18f";
}
.#{$md-css-prefix}-insert-emoticon:before {
  content: "\f190";
}
.#{$md-css-prefix}-insert-invitation:before {
  content: "\f191";
}
.#{$md-css-prefix}-insert-link:before {
  content: "\f192";
}
.#{$md-css-prefix}-insert-photo:before {
  content: "\f193";
}
.#{$md-css-prefix}-merge-type:before {
  content: "\f194";
}
.#{$md-css-prefix}-mode-comment:before {
  content: "\f195";
}
.#{$md-css-prefix}-mode-edit:before {
  content: "\f196";
}
.#{$md-css-prefix}-publish:before {
  content: "\f197";
}
.#{$md-css-prefix}-vertical-align-bottom:before {
  content: "\f198";
}
.#{$md-css-prefix}-vertical-align-center:before {
  content: "\f199";
}
.#{$md-css-prefix}-vertical-align-top:before {
  content: "\f19a";
}
.#{$md-css-prefix}-wrap-text:before {
  content: "\f19b";
}