//------------------------------------------
// Form
//------------------------------------------
form{
    legend{
        border:none;
        color:$primaryDim;
        font-size:2em;
        font-weight:400;
    }
    .form-group{
        margin-bottom:35px;
    }
    td{
        .form-group{
            margin-bottom:0px !important;

            input[type="number"]{
                text-align:center;
            }
        }
    }
    label{
        font-weight:500 !important;
    }
    input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="select"], textarea{
        box-shadow:none !important;
        border:none !important;
        border-bottom:2px solid $neutral300 !important;
        background:none !important;
        padding:0px !important;
        transition: all 300ms!important;

        &:focus{
            border-bottom:2px solid $primaryDim !important;
        }

        &:focus + .input-info{
            opacity: 100;
        }
        &:focus ~ .group-btn-md{
            border-bottom:2px solid $primaryDim !important;
            color: $primaryDim !important;
        }

        &:disabled{
            border:none !important;
        }
    }
    input[type="radio"]{
      margin-right:5px;

    }

    .next-radio {
      margin-left: 20px;
    }

    .form-span{
        resize:none;
        overflow:auto;
        padding-top:10px !important;
        padding-bottom:5px !important;
    }

    textarea{
        resize:none;
        overflow:auto;
        padding-top:10px !important;
        padding-bottom:5px !important;
    }
}

input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="select"], textarea{
    box-shadow:none !important;
    border:none !important;
    border-bottom:2px solid $neutral300 !important;
    background:none !important;
    padding:0px !important;
    transition: all 300ms!important;

    &:focus{
        border-bottom:2px solid $primaryDim !important;
    }

    &:focus + .input-info{
        opacity: 100;
    }
    &:focus ~ .group-btn-md{
        border-bottom:2px solid $primaryDim !important;
        color: $primaryDim !important;
    }

    &:disabled {
        border:none !important;
    }
}

.checkbox .form-control{
    box-shadow:none !important;
}

//------------------------------------------
// Button
//------------------------------------------
.btn{
    padding-left:30px !important;
    padding-right:30px !important;
    font-weight:500 !important;
    outline: none !important;
    @extend .z-depth-1;

    &:focus{
        outline: none !important;
    }

    &:active{
        outline: none !important;
    }

    i{
        margin-right:7px;
    }
}

.btn.btn-accent{
    background:$accentDim;

    &:hover{
        background:$accentDim;
    }
}
.btn.glyphicon{
    padding: 6px 12px !important;
    color: #fff !important;
    border-radius: 5px;
    margin-left: 2px !important;
}

.btn.btn-link{
    color:$accentDim;
    box-shadow:none;
    padding-left:0px !important;
    padding-right:0px !important;
    text-transform: uppercase;
    font-size: 16px;

    &:hover{
        text-decoration: none;
        color:$accentDark;
    }
}

.btn-link-container{
    .btn-link{
        margin-right:20px;
    }
}

//------------------------------------------
// Table
//------------------------------------------
table{
    th {
        // padding-left:15px !important;
        // padding-right:15px !important;
    }
    td {
        // padding:9px !important;
        // padding-left:15px !important;
    }
    tbody {
        td.action {
            padding-left: 5px;
            padding-right: 5px;
            width: 40px
        }
    }
}

//------------------------------------------
// Modal
//------------------------------------------
.modal-header, .modal-footer{
    border:none !important;
}

.modal-dialog{
    h4{
        color:$primaryDim;
    }
}

.modal-content{
    padding-left:15px !important;
    padding-right:15px !important;
}

.modal-footer{
    .btn.btn-link{
        margin-left:15px;
    }
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width:1200px;
  }
}

//------------------------------------------
// Alert
//------------------------------------------
.alert-wrap{
    // padding:0px 20px;

    .alert{
        // margin-top:20px;
        // margin-bottom:-20px;
    }
}

//------------------------------------------
// Datepicker
//------------------------------------------
.bootstrap-datetimepicker-widget{
    th{
        padding:6px !important;
    }
    td{
        padding:6px !important;
    }

    .btn{
        padding:6px 6px !important;
        box-shadow:none;

    }

    .timepicker{
        td{
            a:hover{
                background:none !important;
            }
            span{
                width:auto !important;
                height:auto !important;
                display:block !important;
                padding:3px;

                &:hover{
                    //background:none !important
                }
            }
        }
    }
}

.hideFade{
  display:none;
}
.alert {
    margin-right: 2px;
}
.action-inline {
    form {
        width: 40px;
        display: inline;
    }
}
table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after {
    right: 0px !important;
    left:-10px !important;
    top: inherit !important;
    bottom: 8px !important;
}

.modal-footer{
    text-align: left!important;
}

.tooltip-transparent + .tooltip > .tooltip-inner {
  background-color: transparent!important;
  opacity: 1.0!important;
  filter: alpha(opacity=100)!important;
  display: inline!important;
}

.selected {
   box-shadow:5px 12px 22px 2px #333;
}

.invoice-style-image-upload {
    margin: 0px!important;
}
