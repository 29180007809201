.#{$md-css-prefix}-cake:before {
  content: "\f2c9";
}
.#{$md-css-prefix}-domain:before {
  content: "\f2ca";
}
.#{$md-css-prefix}-location-city:before {
  content: "\f2cb";
}
.#{$md-css-prefix}-mood:before {
  content: "\f2cc";
}
.#{$md-css-prefix}-notifications-none:before {
  content: "\f2cd";
}
.#{$md-css-prefix}-notifications:before {
  content: "\f2ce";
}
.#{$md-css-prefix}-notifications-off:before {
  content: "\f2cf";
}
.#{$md-css-prefix}-notifications-on:before {
  content: "\f2d0";
}
.#{$md-css-prefix}-notifications-paused:before {
  content: "\f2d1";
}
.#{$md-css-prefix}-pages:before {
  content: "\f2d2";
}
.#{$md-css-prefix}-party-mode:before {
  content: "\f2d3";
}
.#{$md-css-prefix}-group:before {
  content: "\f2d4";
}
.#{$md-css-prefix}-group-add:before {
  content: "\f2d5";
}
.#{$md-css-prefix}-people:before {
  content: "\f2d6";
}
.#{$md-css-prefix}-people-outline:before {
  content: "\f2d7";
}
.#{$md-css-prefix}-person:before {
  content: "\f2d8";
}
.#{$md-css-prefix}-person-add:before {
  content: "\f2d9";
}
.#{$md-css-prefix}-person-outline:before {
  content: "\f2da";
}
.#{$md-css-prefix}-plus-one:before {
  content: "\f2db";
}
.#{$md-css-prefix}-poll:before {
  content: "\f2dc";
}
.#{$md-css-prefix}-public:before {
  content: "\f2dd";
}
.#{$md-css-prefix}-school:before {
  content: "\f2de";
}
.#{$md-css-prefix}-share:before {
  content: "\f2df";
}
.#{$md-css-prefix}-whatshot:before {
  content: "\f2e0";
}