// Font path
// --------------------------
@font-face {
  font-family: 'Material Design Iconic Font';
  src: url('#{$md-font-path}/Material-Design-Iconic-Font.eot?v=#{$md-version}');
  src: url('#{$md-font-path}/Material-Design-Iconic-Font.eot?#iefix&v=#{$md-version}') format('embedded-opentype'),
  url('#{$md-font-path}/Material-Design-Iconic-Font.woff?v=#{$md-version}') format('woff'),
  url('#{$md-font-path}/Material-Design-Iconic-Font.ttf?v=#{$md-version}') format('truetype'),
  url('#{$md-font-path}/Material-Design-Iconic-Font.svg?v=#{$md-version}#Material-Design-Iconic-Font') format('svg');
  font-weight: normal;
  font-style: normal;
}