// Mixins
// --------------------------
@mixin md-icon-rotate($degrees, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation);
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin md-icon-flip($horizontal, $vertical, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation, mirror=1);
  -webkit-transform: scale($horizontal, $vertical);
  -ms-transform: scale($horizontal, $vertical);
  transform: scale($horizontal, $vertical);
}