//------------------------------------------
// Tab panel
//------------------------------------------
.page__tab{
    position:relative;

    .float-button{
        position:absolute;
        top:-10px;
        right:-5px;
    }

    .paper-wrap{
        .float-button{
            top:10px;
            right:15px;
        }
    }
}

//------------------------------------------
// Paper card wrap
//------------------------------------------
.paper-wrap, .paper{
    background:#fff;
    @extend .z-depth-1;
    padding:20px;
    @extend .clearfix;

    h3{
        margin-top:0px;
        color:$primaryDim;
        margin-bottom:25px;
    }
    .parent-hint{
        padding-top:5px;
        color:$secondaryTextDark;
        display:block;
        font-size:14px;
    }

    .btn-card-toggle{
        padding-top:10px;
        color:$hintsTextDark;
        &:hover{
            cursor:pointer;
            color:$secondaryTextDark;
        }
    }

    .card-head{
        position: relative;
        @extend .clearfix;
        h3{
            float:left;
        }
        span{
            float:right;
        }

        .big-caret {
            color:black;
            font-size:30px;
            position: absolute;
            top: 0;
            right: 0;
            padding-top: 0;
        }
        .big-caret {
            color:black;
            font-size:30px;
            position: absolute;
            top: 0;
            right: 0;
            padding-top: 0;
        }
    }
}

.paper-wrap-container{
    .paper-wrap{
        margin-bottom:25px;

        .float-button{
            top:15px;
            right:15px;
        }
    }
}

.card.paper{
    @extend .paper-wrap;
    margin-bottom:25px;
    position:relative;

    .float-button{
        top:10px;
        right:15px;
    }

    .card-head{
        h3{
            margin-bottom:25px;
        }
    }
}

.navbar-text{
    margin-bottom: 0 !important;
    margin-top: 6px !important;
    label{
        color: $accentDim!important;
    }
}

//------------------------------------------
// Material design float button
//------------------------------------------
a.float-button, button.float-button {
    @extend .z-depth-1;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    height: 55.5px;
    letter-spacing: 0.5px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 55.5px;
    will-change: opacity, transform;
    z-index: 1;
    perspective-origin: 27.75px 27.75px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    transform-origin: 27.75px 27.75px;
    background: $accentDim none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(255, 255, 255);
    border-radius: 50% 50% 50% 50%;
    font: normal normal normal normal 15px/56px Roboto, sans-serif;
    outline: rgb(255, 255, 255) none 0px;
    overflow: hidden;
    transition: box-shadow 0.3s ease-out 0s;
    &:hover{
        @extend .z-depth-2;
    }
}

//------------------------------------------
// Round button
//------------------------------------------
.round{
    width:75px;
    height:75px;
    border-radius:50%;
    overflow:hidden;
    background:$neutral200;

    img{
        height:inherit;
    }
}

//------------------------------------------
// Table list material designs tyle
//------------------------------------------
.table-list{
    th{
        border-bottom:1px !important;
    }
    td{
        vertical-align: middle !important;

        &.img{
            width:100px;
            border:none !important;
        }

        &.action{
            text-align:right;
            a{
                font-weight:500;
                margin-left:15px;
                color:$neutral500;
                &:hover{
                    color:$primaryDim;
                    text-decoration: none;
                }
            }
        }

        ul{
            padding-left:15px;

            &.cruisedate-list{
                list-style: none;
                padding-left:0px;
                span.label-cruisedate{
                    font-weight:bold;
                    display:inline-block;
                    width:50px;
                    padding-right:15px;
                    text-align: right;
                }
            }
        }

        span{
            &.td-label{
                display:inline-block; text-align:right; margin-right:10px;
                font-size:inherit !important;
                &.small{
                    width:35px;
                }

                &.large{
                    width:75px;
                }
            }
        }
    }
}

.table-key-value{
    tbody tr{
        &:first-child{
            th,td{
                border:none;
            }
        }
    }
    th, td{
        border:none !important;
        padding:15px !important;
    }
}

//------------------------------------------
// Datatables
//------------------------------------------
.table.dataTable.table-datatable{
    //margin-top:15px !important;
}

.dataTables_info{
    color:$hintsTextDark;
}

.dataTables_filter {
     display: none;
}

$borderColor: #ddd !important;

table {
    .bold-border {
        //border: none !important;
        border-left: 2px solid !important;
        border-right: 2px solid !important;
        //border-bottom: 1px solid !important;
        border-color: $borderColor;
    }
    .bold-border-left {
        //border: none !important;
        border-left: 2px solid !important;
        //border-bottom: 1px solid !important;
        border-color: $borderColor;
    }
    .bold-border-right {
        //border: none !important;
        border-right: 2px solid !important;
        //border-bottom: 1px solid !important;
        border-color: $borderColor;
    }
    thead {
        .bold-border,
        .bold-border-left,
        .bold-border-right {
            border-bottom: 2px solid !important;
            border-color: $borderColor;
        }
    }

    .warning {
        border-top: 2px solid !important;
        border-bottom: 2px solid !important;
        border-color: $borderColor;
    }
}


//------------------------------------------
// Filterbar
//------------------------------------------
.filterbar{
    // height:35px;
    // padding:5px 15px;
    background:$neutral50;
    margin-bottom:5px;

    .input-group-addon{
        border:none;
        background:$neutral50;
    }
    .input-group{
        border-bottom:2px solid transparent;
        transition: all 300ms;
        input{
            border:none !important;
            box-shadow:none !important;
            background:$neutral50;

            &:focus{
                border:none !important;
                box-shadow:none !important;
                background:$neutral50;
            }
        }
    }
}

//------------------------------------------
// Form extensions
//------------------------------------------
//@TODO: Refactor out this hack
.reservation-cabins, .reservation-passenger {

    .select2-container {
        width: 100% !important;
    }
}


form{
    .select2{
        //width:100% !important;
    }

    .select2-container{
        width:100% !important;
    }

    .select2-container--disabled{
        .select2-selection.select2-selection--single{
            border:none;
            background:none;
            b{
                display:none;
            }

            .select2-selection__rendered{
                padding-left:0px;
            }
        }
    }

    .img-input-preview{
        display:block;
        margin:0 auto;
        margin-bottom:15px;
    }
}

//------------------------------------------
// Partner allotment create table
//------------------------------------------
.table-partner-allotment{
    display:none;
    th{
        a{
            color:$secondaryTextDark;
            &:hover{
                color:$primaryMedium;
            }
        }

        i{
            padding-left:10px;
        }
    }
    tr{
        &:hover{
            background:none !important;
        }

        &.allotment-cabins-row{
            &:hover{
                background: none !important;
                border-top:none !important;
            }

            td{
                border-top: none !important;
                padding-top:0px !important;
            }
        }
    }

    tr[class*="allotment-cabins-row-"]{
        &:hover{
            background: none !important;
            border-top:none !important;
        }

        td{
            border-top: none !important;
            padding-top:0px !important;
        }
    }

    .cb-allotment-mass-check{
        margin:0px;

        label{
            min-height:15px;
        }
    }
}

.modal{
    h3{
        margin-top:0px;
        color:$primaryDim;
        margin-bottom:25px;
    }
    .parent-hint{
        padding-top:5px;
        color:$secondaryTextDark;
        display:block;
        font-size:14px;
    }
}
