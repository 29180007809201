// Pulled icons
// -------------------------
[class^="#{$md-css-prefix}-"], [class*=" #{$md-css-prefix}-"] {
  &.pull-left {
    float: left;
    margin-right: .3em;
  }
  
  &.pull-right {
    float: right;
    margin-left: .3em;
  }
}