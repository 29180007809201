.#{$md-css-prefix}-check-box:before {
  content: "\f2e1";
}
.#{$md-css-prefix}-check-box-outline-blank:before {
  content: "\f2e2";
}
.#{$md-css-prefix}-radio-button-off:before {
  content: "\f2e3";
}
.#{$md-css-prefix}-radio-button-on:before {
  content: "\f2e4";
}
.#{$md-css-prefix}-star:before {
  content: "\f2e5";
}
.#{$md-css-prefix}-star-half:before {
  content: "\f2e6";
}
.#{$md-css-prefix}-star-outline:before {
  content: "\f2e7";
}