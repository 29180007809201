.#{$md-css-prefix}-add:before {
  content: "\f0fb";
}
.#{$md-css-prefix}-add-box:before {
  content: "\f0fc";
}
.#{$md-css-prefix}-add-circle:before {
  content: "\f0fd";
}
.#{$md-css-prefix}-add-circle-outline:before {
  content: "\f0fe";
}
.#{$md-css-prefix}-archive:before {
  content: "\f0ff";
}
.#{$md-css-prefix}-backspace:before {
  content: "\f100";
}
.#{$md-css-prefix}-block:before {
  content: "\f101";
}
.#{$md-css-prefix}-clear:before {
  content: "\f102";
}
.#{$md-css-prefix}-content-copy:before {
  content: "\f103";
}
.#{$md-css-prefix}-content-cut:before {
  content: "\f104";
}
.#{$md-css-prefix}-content-paste:before {
  content: "\f105";
}
.#{$md-css-prefix}-create:before {
  content: "\f106";
}
.#{$md-css-prefix}-drafts:before {
  content: "\f107";
}
.#{$md-css-prefix}-filter-list:before {
  content: "\f108";
}
.#{$md-css-prefix}-flag:before {
  content: "\f109";
}
.#{$md-css-prefix}-forward:before {
  content: "\f10a";
}
.#{$md-css-prefix}-gesture:before {
  content: "\f10b";
}
.#{$md-css-prefix}-inbox:before {
  content: "\f10c";
}
.#{$md-css-prefix}-link:before {
  content: "\f10d";
}
.#{$md-css-prefix}-mail:before {
  content: "\f10e";
}
.#{$md-css-prefix}-markunread:before {
  content: "\f10f";
}
.#{$md-css-prefix}-redo:before {
  content: "\f110";
}
.#{$md-css-prefix}-remove:before {
  content: "\f111";
}
.#{$md-css-prefix}-remove-circle:before {
  content: "\f112";
}
.#{$md-css-prefix}-remove-circle-outline:before {
  content: "\f113";
}
.#{$md-css-prefix}-reply:before {
  content: "\f114";
}
.#{$md-css-prefix}-reply-all:before {
  content: "\f115";
}
.#{$md-css-prefix}-report:before {
  content: "\f116";
}
.#{$md-css-prefix}-save:before {
  content: "\f117";
}
.#{$md-css-prefix}-select-all:before {
  content: "\f118";
}
.#{$md-css-prefix}-send:before {
  content: "\f119";
}
.#{$md-css-prefix}-sort:before {
  content: "\f11a";
}
.#{$md-css-prefix}-text-format:before {
  content: "\f11b";
}
.#{$md-css-prefix}-undo:before {
  content: "\f11c";
}