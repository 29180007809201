.#{$md-css-prefix}-business:before {
  content: "\f0d4";
}
.#{$md-css-prefix}-call:before {
  content: "\f0d5";
}
.#{$md-css-prefix}-call-end:before {
  content: "\f0d6";
}
.#{$md-css-prefix}-call-made:before {
  content: "\f0d7";
}
.#{$md-css-prefix}-call-merge:before {
  content: "\f0d8";
}
.#{$md-css-prefix}-call-missed:before {
  content: "\f0d9";
}
.#{$md-css-prefix}-call-received:before {
  content: "\f0da";
}
.#{$md-css-prefix}-call-split:before {
  content: "\f0db";
}
.#{$md-css-prefix}-chat:before {
  content: "\f0dc";
}
.#{$md-css-prefix}-clear-all:before {
  content: "\f0dd";
}
.#{$md-css-prefix}-comment:before {
  content: "\f0de";
}
.#{$md-css-prefix}-contacts:before {
  content: "\f0df";
}
.#{$md-css-prefix}-dialer-sip:before {
  content: "\f0e0";
}
.#{$md-css-prefix}-dialpad:before {
  content: "\f0e1";
}
.#{$md-css-prefix}-dnd-on:before {
  content: "\f0e2";
}
.#{$md-css-prefix}-email:before {
  content: "\f0e3";
}
.#{$md-css-prefix}-forum:before {
  content: "\f0e4";
}
.#{$md-css-prefix}-import-export:before {
  content: "\f0e5";
}
.#{$md-css-prefix}-invert-colors-off:before {
  content: "\f0e6";
}
.#{$md-css-prefix}-invert-colors-on:before {
  content: "\f0e7";
}
.#{$md-css-prefix}-live-help:before {
  content: "\f0e8";
}
.#{$md-css-prefix}-location-off:before {
  content: "\f0e9";
}
.#{$md-css-prefix}-location-on:before {
  content: "\f0ea";
}
.#{$md-css-prefix}-message:before {
  content: "\f0eb";
}
.#{$md-css-prefix}-messenger:before {
  content: "\f0ec";
}
.#{$md-css-prefix}-no-sim:before {
  content: "\f0ed";
}
.#{$md-css-prefix}-phone:before {
  content: "\f0ee";
}
.#{$md-css-prefix}-portable-wifi-off:before {
  content: "\f0ef";
}
.#{$md-css-prefix}-quick-contacts-dialer:before {
  content: "\f0f0";
}
.#{$md-css-prefix}-quick-contacts-mail:before {
  content: "\f0f1";
}
.#{$md-css-prefix}-ring-volume:before {
  content: "\f0f2";
}
.#{$md-css-prefix}-stay-current-landscape:before {
  content: "\f0f3";
}
.#{$md-css-prefix}-stay-current-portrait:before {
  content: "\f0f4";
}
.#{$md-css-prefix}-stay-primary-landscape:before {
  content: "\f0f5";
}
.#{$md-css-prefix}-stay-primary-portrait:before {
  content: "\f0f6";
}
.#{$md-css-prefix}-swap-calls:before {
  content: "\f0f7";
}
.#{$md-css-prefix}-textsms:before {
  content: "\f0f8";
}
.#{$md-css-prefix}-voicemail:before {
  content: "\f0f9";
}
.#{$md-css-prefix}-vpn-key:before {
  content: "\f0fa";
}