// Spinning Icons
// --------------------------
.#{$md-css-prefix}-spin {
  -webkit-animation: md-spin 1.5s infinite linear;
  animation: md-spin 1.5s infinite linear;
}

.#{$md-css-prefix}-spin-reverse {
  -webkit-animation: md-spin-reverse 1.5s infinite linear;
  animation: md-spin-reverse 1.5s infinite linear;
}

// Spin
@-webkit-keyframes md-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes md-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

// Spin Reverse
@-webkit-keyframes md-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes md-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

